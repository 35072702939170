// Step1.js

import React from 'react';
import '../global.css';

function Step1({onSubmit, coas, fas}) {

  return (
    <div>
      <div className="block">
        <h1 className="title is-4" style={{ marginBottom: '8px' }}>Summary</h1>
      </div>
      <form onSubmit={onSubmit}>

        <div className="box is-draft" style={{ padding: '16px 24px' }}>
          <h3 className="title is-6" style={{ marginBottom: '16px' }}>Factual Allegations</h3>
          <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
            {fas.map((item, index) => (
              <li key={index} className='body'>
                {item}
              </li>
            ))}
          </ul>
        </div>

        <div className="box is-draft">
          <h3 className="title is-6" style={{ marginBottom: '16px' }}>Causes of Action</h3>
          <ol style={{ paddingLeft: "20px" }}>
            {coas.map((item, index) => (
              <li key={index} className='body'>
                <div style={{ whiteSpace: 'pre-wrap' }}>{item}</div>
              </li>
            ))}
          </ol>
        </div>

        <div className="has-text-right">
          <button className="button is-normal is-success" type="submit">Legal element assessment</button>
        </div>
      </form>
    </div>
  );
}

export default Step1;
